<template>
  <div class="media contentWrapper">
    <h1>Media Kit</h1>
    <p>
      Download high resolution artwork of TrackIR products and logos for use in
      online stores and publications.
    </p>
    <h2>TrackIR Logos</h2>
    <a class="btn" :href="mediakitUrl + 'TrackIR-Logos.zip'"
      >Download All (.zip)</a
    >
    <h2>TrackIR 5</h2>
    <p>
      Featuring a wide field of view, high frame rate, and 6DoF tracking
      technology, the TrackIR 5 dramatically increases situational awareness,
      adding depth to the PC gaming experience and pulling you further into the
      game.
    </p>
    <three-wide>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'tir-front-green-lights.png'" />
        <a :href="mediakitUrl + 'TrackIR5-Front-On.png.zip'">Download (.zip)</a>
      </div>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'tir-front-no-lights.png'" />
        <a :href="mediakitUrl + 'TrackIR5-Front-Off.png.zip'"
          >Download (.zip)</a
        >
      </div>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'tir-back.png'" />
        <a :href="mediakitUrl + 'TrackIR5-Back.png.zip'">Download (.zip)</a>
      </div>
    </three-wide>
    <three-wide>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'tir-side.png'" />
        <a :href="mediakitUrl + 'TrackIR5-Side.png.zip'">Download (.zip)</a>
      </div>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'tir-perspective-bent.png'" />
        <a :href="mediakitUrl + 'TrackIR5-Perspective-ArmsBent.png.zip'"
          >Download (.zip)</a
        >
      </div>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'tir-perspective.png'" />
        <a :href="mediakitUrl + 'TrackIR5-Perspective.png.zip'"
          >Download (.zip)</a
        >
      </div>
    </three-wide>
    <a :href="mediakitUrl + 'TrackIR5.zip'" class="btn">Download All (.zip)</a>
    <h2>TrackClip Pro</h2>
    <two-wide>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'trackclip-pro-headset.png'" />
        <a :href="mediakitUrl + 'TrackClip-Pro-Headset.png.zip'"
          >Download (.zip)</a
        >
      </div>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'trackclip-pro.png'" />
        <a :href="mediakitUrl + 'TrackClip-Pro.png.zip'">Download (.zip)</a>
      </div>
    </two-wide>
    <a :href="mediakitUrl + 'TrackClip-Pro.zip'" class="btn"
      >Download All (.zip)</a
    >
    <h2>TrackClip</h2>
    <div class="mediaDownload">
      <img :src="imagesUrl + 'track-clip.png'" />
      <a :href="mediakitUrl + 'TrackClip.png.zip'">Download (.zip)</a>
    </div>
    <h2>Hats</h2>
    <two-wide>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'trackir-hat-white.png'" />
        <a :href="mediakitUrl + 'TrackIR-Hat-White.png.zip'">Download (.zip)</a>
      </div>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'trackir-hat-black.png'" />
        <a :href="mediakitUrl + 'TrackIR-Hat-Black.png.zip'">Download (.zip)</a>
      </div>
    </two-wide>
    <a :href="mediakitUrl + 'TrackIR-Hats.zip'" class="btn"
      >Download All (.zip)</a
    >
    <h2>Visors</h2>
    <two-wide>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'trackir-visor-white.png'" />
        <a :href="mediakitUrl + 'TrackIR-Visor-White.png.zip'"
          >Download (.zip)</a
        >
      </div>
      <div class="mediaDownload">
        <img :src="imagesUrl + 'trackir-visor-black.png'" />
        <a :href="mediakitUrl + 'TrackIR-Visor-Black.png.zip'"
          >Download (.zip)</a
        >
      </div>
    </two-wide>
    <a :href="mediakitUrl + 'TrackIR-Visors.zip'" class="btn"
      >Download All (.zip)</a
    >
  </div>
  <!-- END media -->
</template>
<script>
import { IMAGES_URL, MEDIAKIT_URL } from "@/constants/";
import ThreeWide from "@/components/Layouts/ThreeWide";
import TwoWide from "@/components/Layouts/TwoWide";
export default {
  name: "Media",
  computed: {
    mediakitUrl: function () {
      return MEDIAKIT_URL;
    },
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
  components: {
    ThreeWide,
    TwoWide,
  },
};
</script>
<style lang="scss" scoped>
.media {
  text-align: center;
  padding-bottom: 70px;

  p {
    padding: 0 40px;
  }

  h2 {
    margin-top: 80px;
  }
}
.mediaDownload {
  margin-bottom: 20px;
  a {
    text-decoration: underline;
    display: block;
  }
}
</style>
