<template>
  <div class="twoWide" :class="className">
    <h1 v-if="title">{{ title }}</h1>
    <div class="contentWrapper">
      <slot></slot>
    </div>
    <!-- END content -->
  </div>
  <!-- END threeWide -->
</template>

<script>
export default {
  name: "TwoWide",
  props: ["title","className"]
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.twoWide {
  padding: 65px 0;
}
h1 {
  text-align: center;
  font-size: 45px;
}
.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items:center;
  //grid-column-gap: 10px;
  grid-row-gap: 50px;

  @include for-medium-up {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.gap-medium .contentWrapper {
  grid-column-gap: 40px;
}
</style>
